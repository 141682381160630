import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";

import { AuthServices } from "../auth.service";
import { Injectable } from "@angular/core";
import { endpoints } from "src/app/_config/url-endpoint";

// this.authService.getAccessHeaders() replacement
// const authHeaders = new HttpHeaders({
//   "Access-Control-Allow-Origin": "*",
//   authorization:
//     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzQyODIwNDcsImlkIjoiYjAwYzA5OTQtNDNhNi00ZDg1LWE4ZjYtODkzMGFhMjAxNTQxIiwidXNlcklkIjoiYjAwYzA5OTQtNDNhNi00ZDg1LWE4ZjYtODkzMGFhMjAxNTQxIiwibW9iaWxlTnVtYmVyIjoiNTIxODQ2NDY0IiwiZW1haWwiOiJtdWJhcmFrQHlvcG1haWwuY29tIiwiY291bnRyeUNvZGUiOiIrOTcxIiwicm9sZSI6IlNVUEVSX0FETUlOIiwicGFydG5lcklkIjpudWxsLCJzb3VyY2UiOiJXRUJfUE9SVEFMIiwiaWF0IjoxNzMxNjU0MDQ3fQ.p1DstDU62IIKE7lrKFSZUyuzKHkkoVxBEoCjsVrDliE",
//   // "Content-Type": "application/json",
//   "Cache-Control": "no-cache",
//   Pragma: "no-cache",
//   Expires: "-1",
//   "If-Modified-Since": "0",
//   "ngrok-skip-browser-warning": "true",
// });

const yachtPartnerUrl = endpoints.yacht.partnerUrl;

@Injectable({
  providedIn: "root",
})
export class YachtPartnerService {
  private savePartnerVal = new Subject<any>();

  constructor(private http: HttpClient, private authService: AuthServices) {}

  // done
  getPartners(queryString): Observable<any> {
    let url = `${yachtPartnerUrl}?${queryString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  createPartner(body): Observable<any> {
    return this.http.post(
      `${yachtPartnerUrl}`,
      body,
      this.authService.getAccessHeaders()
    );
  }

  // done
  getPartnerDetails(partnerId): Observable<any> {
    return this.http.get(
      yachtPartnerUrl + "/" + partnerId,
      this.authService.getAccessHeaders()
    );
  }

  // done
  editPartner(partnerId, body): Observable<any> {
    return this.http.patch(
      yachtPartnerUrl + "/" + partnerId,
      body,
      this.authService.getAccessHeaders()
    );
  }

  // ...
  getPartnerCount(): Observable<any> {
    let tenantUrlString = "";
    let url = `${yachtPartnerUrl}/status-count${tenantUrlString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  changePartnerStatus(partnerId, body): Observable<any> {
    return this.http.post(
      endpoints.partnerPage.changePartnerStatus + partnerId,
      body,
      this.authService.getAccessHeaders()
    );
  }

  getPartner(): Observable<any> {
    return this.http.get(
      endpoints.partnerPage.getPartner,
      this.authService.getAccessHeaders()
    );
  }

  downloadFile(queryString): Observable<any> {
    const url = `${yachtPartnerUrl}/export?${queryString}`;
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(url, {
      headers: (this.authService.getAccessHeaders() as { headers: HttpHeaders })
        .headers,
      responseType: "blob",
    });
  }

  public setRefreshPartner(data: any) {
    this.savePartnerVal.next({ result: data });
  }

  public getRefreshPartner(): Observable<any> {
    return this.savePartnerVal.asObservable();
  }
}
