import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { AuthServices } from "../../../_services/auth.service";
import { GlobalServices } from "src/app/_services/global.service";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { configImage, ROLE, localStorageVal } from "src/app/_config/config";
import { routerLinks } from "src/app/_config/router-links";
import { PartnerService } from "src/app/_services/partner.service";

@Component({
  selector: "app-nav-left",
  templateUrl: "./nav-left.component.html",
  styleUrls: ["./nav-left.component.scss"],
})
export class NavLeftComponent implements OnInit, OnDestroy {
  public carActivities = routerLinks.activities.car;
  public yachtActivities = routerLinks.activities.yacht;
  public personalAssistantsTasks = routerLinks.activities.personalAssistantsTasks;
  public routeProfile = routerLinks.profile.profileHome;
  public routeAdministration = routerLinks.administration.administrationHome;
  public routePartnersCars = routerLinks.partners.cars;
  public routePartnersYachts = routerLinks.partners.yachts;
  public routeCars = routerLinks.assets.carHome;
  public routeYachts = routerLinks.assets.yachtHome;
  public routeCustomers = routerLinks.customers.customersHome;
  public routeAnalytics = routerLinks.analytics.analyticsHome;
  public promoCode = routerLinks.promoCode.promoCodeHome;
  public reasonOfCancellation = routerLinks.reasonOfCancellation.reasonOfCancellation;
  public promotions = routerLinks.promotions.promotions;
  public company = routerLinks.company.company;
  public setting = routerLinks.setting.setting;
  public carReports = routerLinks.reports.carReports;
  public yachtReports = routerLinks.reports.yachtReports;
  public ourfleet = routerLinks.ourfleet.ourfleet;
  public kpi = routerLinks.kpi.kpi;
  public feed = routerLinks.liveFeed.liveFeed;
  public serviceRequests = routerLinks.serviceRequests.serviceRequests;
  public logo = configImage.logo;
  public logoIcon = configImage.logoIcon;
  public userDefault = configImage.userDefault;
  public dashboard = configImage.dashboard;
  public administration = configImage.administration;
  public cars = configImage.cars;
  public drivers = configImage.drivers;
  public customers = configImage.customers;
  public newChatCount = 0;
  isSuperAdmin = false;
  isLimoAdmin = true;
  role: string = localStorage.getItem("role");
  companyId;
  public userDetails: any = {
    fullName: "",
    role: "",
  };
  open = false;
  public roleName: string;
  public rowData: any = [];
  @ViewChild("hamburger") hamburger: ElementRef;
  @ViewChild("menuWap") menuWap: ElementRef;
  @ViewChild("logoImg") logoImg: ElementRef;
  @ViewChild("nav") nav: ElementRef;
  public subscription: Subscription;
  public profileImage: any;
  public innerWidth: any;
  public smallScreen = false;
  constructor(
    private authService: AuthServices,
    public toastrService: ToastrService,
    private translate: TranslateService,
    private globalService: GlobalServices,
    private partnerService: PartnerService,
  ) {
    this.roleName = localStorage.getItem("role");
    if (this.role === "PARTNER" && !localStorage.getItem(localStorageVal.tenantId)) {
      this.getPartner();
    }
  }
  showSubMenu: string;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth <= 600) {
      this.smallScreen = true;
    } else {
      this.smallScreen = false;
    }
  }
  // toggleSubMenu(string): void {
  //   this.showSubMenu = !this.showSubMenu;
  // }
  public toggleSubMenu(activeTabVal: string) {
    this.showSubMenu = activeTabVal === this.showSubMenu ? "" : activeTabVal;
  }
  onNavClick(e) {
    if (!this.open) {
      this.hamburger.nativeElement.classList.add("open");
      this.logoImg.nativeElement.classList.add("show");
      this.menuWap.nativeElement.classList.add("show");
      this.nav.nativeElement.classList.add("show");
      this.globalService.updateValue("4.6rem");
    } else {
      this.hamburger.nativeElement.classList.remove("open");
      this.nav.nativeElement.classList.remove("show");
      this.logoImg.nativeElement.classList.remove("show");
      this.menuWap.nativeElement.classList.remove("show");
      this.globalService.updateValue("15.6rem");
    }
    this.open = !this.open;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 600) {
      this.smallScreen = true;
    } else {
      this.smallScreen = false;
    }

    this.checkIsLoggedIn(this.role, this.companyId);
    this.getUserDetails();
    this.getMe();
  }

  async getPartner() {
    await this.partnerService.getPartner().subscribe((response) => {
      if (response && response.code === 200) {
        const tenantId =
          response.result.partnerDetails?.tenantId && response.result.partnerDetails?.tenantId != "null"
            ? response.result.partnerDetails?.tenantId
            : "";
        localStorage.setItem(localStorageVal.tenantId, tenantId);
      }
    });
  }

  getMe() {
    this.authService.getMe().subscribe(
      (response) => {
        if (response && response.code === 200) {
          this.rowData = response.result;
        }
      },
      (error) => {
        setTimeout(() => {
          if (!error.error.message) {
            this.toastrService.error(this.translate.instant("errors.connectionError"), "");
          } else {
            this.toastrService.error(error.error.message, "");
          }
        }, 1000);
      },
    );
  }

  public openfireUserId;
  public chauffeursNew;
  public customerNew;
  public chauffeursResolve;
  public customerResolve;
  public totalChauffer;
  public totalCustomer;
  public totalCount;

  checkIsLoggedIn(role, companyId) {
    if (role === "SUPER_ADMIN") {
      this.isSuperAdmin = true;
    }
    if ((role === "ADMIN" && companyId != "") || companyId != null) {
      this.isLimoAdmin = false;
    }
  }
  getUserDetails() {
    this.subscription = this.authService.getProfileImageData().subscribe(
      (response) => {
        if (response) {
          this.userDetails = JSON.parse(JSON.stringify(response));
        }
      },
      (error) => {
        if (!error.error.message) {
          this.toastrService.error(this.translate.instant("errors.connectionError"), "");
        } else {
          this.toastrService.error(error.error.message, "");
        }
      },
    );
  }
  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
