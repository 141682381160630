import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

import { AuthServices } from "../auth.service";
import { Injectable } from "@angular/core";
import { endpoints } from "src/app/_config/url-endpoint";

@Injectable({
  providedIn: "root",
})
export class YachtService {
  private saveYachtVal = new Subject<any>();

  constructor(private http: HttpClient, private authService: AuthServices) {}

  // done
  getYachtAnalytics(): Observable<any> {
    let url = `${endpoints.yacht.yachtUrl}/yacht-analytics`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getYachts(queryString): Observable<any> {
    let url = `${endpoints.yacht.yachtUrl}?${queryString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getMiscellaneous(partnerId?: string): Observable<any> {
    let url = `${endpoints.yacht.miscellaneous}`;
    if (partnerId) {
      url = `${endpoints.yacht.miscellaneous}?partnerId=${partnerId}`;
    }
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getFacility(): Observable<any> {
    let url = `${endpoints.yacht.facilitiesUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getAmenities(): Observable<any> {
    let url = `${endpoints.yacht.amenitiesUrl}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getWaterSports(partnerId?: string): Observable<any> {
    let url = `${endpoints.yacht.waterSportsUrl}`;
    if (partnerId) {
      url = `${endpoints.yacht.waterSportsUrl}?partnerId=${partnerId}`;
    }
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getCaterings(partnerId?: string): Observable<any> {
    let url = `${endpoints.yacht.cateringUrl}`;
    if (partnerId) {
      url = `${endpoints.yacht.cateringUrl}?partnerId=${partnerId}`;
    }
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getRoutes(tenantId?: string): Observable<any> {
    let url = `${endpoints.yacht.routesUrl}`;
    if (tenantId) {
      url = `${endpoints.yacht.routesUrl}?tenantId=${tenantId}`;
    }
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  // done
  getYachtDetails(yachtId): Observable<any> {
    return this.http.get(endpoints.yacht.yachtUrl + `/${yachtId}`, this.authService.getAccessHeaders());
  }

  // done
  createYacht(body): Observable<any> {
    return this.http.post(`${endpoints.yacht.yachtUrl}`, body, this.authService.getAccessHeaders());
  }

  // done
  editYacht(yachtId, body): Observable<any> {
    return this.http.patch(endpoints.yacht.yachtUrl + `/${yachtId}`, body, this.authService.getAccessHeaders());
  }

  /**
   *
   *
   * @param {string} [tenantId]
   * @returns {Observable<any>}
   * @memberof YachtService
   */
  getYachtCount(tenantId?: string): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `?tenantId=${tenantId}`;
    }
    let url = `${endpoints.yacht.yachtUrl}/status-count${tenantUrlString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  changeYachtStatus(yachtId, body): Observable<any> {
    return this.http.post(`${endpoints.yacht.yachtUrl}/action/${yachtId}`, body, this.authService.getAccessHeaders());
  }

  // done
  downloadFile(queryParams): Observable<any> {
    const url = `${endpoints.yacht.yachtUrl}/download?${queryParams}`;
    return this.http.get(url, {
      headers: (this.authService.getAccessHeaders() as { headers: HttpHeaders }).headers,
      responseType: "blob",
    });
  }

  // done
  public setRefreshYacht(data: any) {
    this.saveYachtVal.next({ result: data });
  }

  // done
  public getRefreshYacht(): Observable<any> {
    return this.saveYachtVal.asObservable();
  }
}
