export const routerLinks = {
  activities: {
    car: "/bookings/car",
    yacht: "/bookings/yacht",
    personalAssistantsTasks: "/bookings/personal-assistants-tasks",
  },
  profile: {
    profileHome: "/home",
  },
  subscriptions: {
    subscriptionsHome: "/subscriptions",
  },
  administration: {
    administrationHome: "/administration",
  },
  partners: {
    cars: "/partners/cars",
    yachts: "/partners/yachts",
  },
  assets: {
    carHome: "/car",
    yachtHome: "/yacht",
  },
  customers: {
    customersHome: "/customers",
  },
  driverManagement: {
    driverHome: "/driver",
  },
  analytics: {
    analyticsHome: "/analytics",
  },
  notification: {
    notificationHome: "/notification",
  },
  settings: {
    settingHome: "/settings",
  },
  promoCode: {
    promoCodeHome: "/promocode",
  },
  reasonOfCancellation: {
    reasonOfCancellation: "/reason-of-cancellation",
  },
  promotions: {
    promotions: "/promotions",
  },
  company: {
    company: "/company",
  },
  setting: {
    setting: "/settings",
  },
  reports: {
    carReports: "/reports/cars",
    yachtReports: "/reports/yachts",
  },
  ourfleet: {
    ourfleet: "/chauffeurMap",
  },
  kpi: {
    kpi: "/kpi",
  },
  liveFeed: {
    liveFeed: "/feed",
  },
  serviceRequests: {
    serviceRequests: "service-requests",
  },
};
